import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77')
];

export const server_loads = [0,9,10,13,11,14,15,17,18,19,6,7];

export const dictionary = {
		"/(app)": [21,[2]],
		"/(app)/admin": [31,[2,8]],
		"/(app)/admin/audits/[survey_response_id]": [~32,[2,8,9,10]],
		"/(app)/admin/audits/[survey_response_id]/generate_report": [~34,[12]],
		"/(app)/admin/audits/[survey_response_id]/message_order": [~35,[2,8,9,10]],
		"/(app)/admin/audits/[survey_response_id]/survey_response_product/[survey_response_product_id]": [~36,[2,8,9,10,13]],
		"/(app)/admin/audits/[survey_response_id]/survey_response_product/[survey_response_product_id]/edit_product_response": [~38,[2,8,9,10,13]],
		"/(app)/admin/audits/[survey_response_id]/survey_response_product/[survey_response_product_id]/edit_product/[audited_product_id]": [~37,[2,8,9,10,13]],
		"/(app)/admin/audits/[survey_response_id]/survey_response_product/[survey_response_product_id]/link_product/[audited_product_id]": [~39,[2,8,9,10,13]],
		"/(app)/admin/audits/[survey_response_id]/survey_response_product/[survey_response_product_id]/new_product": [~40,[2,8,9,10,13]],
		"/(app)/admin/audits/[survey_response_id]/[entity_name=auditable_schema]/[entity_id]/audit/[audit_id]": [~33,[2,8,9,10,11]],
		"/(app)/admin/concentration-test-exponential": [42,[2,8]],
		"/(app)/admin/concentration-test": [41,[2,8]],
		"/(app)/admin/ingredient-test": [43,[2,8]],
		"/(app)/admin/ingredients": [~44,[2,8]],
		"/(app)/admin/ingredients/[ingredient_id]": [~45,[2,8]],
		"/(app)/admin/products": [46,[2,8]],
		"/(app)/admin/products/new": [~48,[2,8]],
		"/(app)/admin/products/[audited_product_id]": [~47,[2,8]],
		"/(app)/admin/survey_definition": [~49,[2,8,14]],
		"/(app)/admin/survey_definition/message_order": [~51,[2,8,14]],
		"/(app)/admin/survey_definition/[entity_name=auditable_schema]/[entity_id]/audit/[audit_id]": [~50,[2,8,14,15]],
		"/(app)/admin/test-kits": [~52,[2,8]],
		"/(app)/admin/test-kits/new": [~55,[2,8]],
		"/(app)/admin/test-kits/[test_kit_id]": [~53,[2,8]],
		"/(app)/admin/test-kits/[test_kit_id]/edit": [~54,[2,8]],
		"/(app)/admin/test-only-report": [56,[16]],
		"/auth": [70,[20]],
		"/auth/callback": [~71,[20]],
		"/auth/error": [72,[20]],
		"/auth/logout": [73,[20]],
		"/auth/verify": [74,[20]],
		"/(app)/detect-detox": [57,[2,17]],
		"/(app)/detect-detox/kits": [58,[2,17,18]],
		"/(app)/detect-detox/kits/register": [~67,[2,17,18]],
		"/(app)/detect-detox/kits/[test_kit_id]": [59,[2,17,18,19]],
		"/(app)/detect-detox/kits/[test_kit_id]/consents": [~60,[2,17,18,19]],
		"/(app)/detect-detox/kits/[test_kit_id]/lifestyle-audit": [~61,[2,17,18,19]],
		"/(app)/detect-detox/kits/[test_kit_id]/onboarding": [~62,[2,17,18,19]],
		"/(app)/detect-detox/kits/[test_kit_id]/onboarding/survey": [~63,[2,17,18,19]],
		"/(app)/detect-detox/kits/[test_kit_id]/schedule": [64,[2,17,18,19]],
		"/(app)/detect-detox/kits/[test_kit_id]/schedule/complete": [~65,[2,17,18,19]],
		"/(app)/detect-detox/kits/[test_kit_id]/schedule/select": [66,[2,17,18,19]],
		"/(app)/detect-detox/welcome": [~68,[2,17]],
		"/onboarding/profile": [~75],
		"/onboarding/terms-and-conditions": [76],
		"/sentry-example": [77],
		"/(app)/settings/profile": [~69,[2]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]": [22,[2,3,4]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]/complete": [29,[2,3,4]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]/review": [~30,[2,3,4]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]/[survey_section_id]": [23,[2,3,4,5]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]/[survey_section_id]/review": [~28,[2,3,4,5]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]/[survey_section_id]/[question_id]": [24,[2,3,4,5,6]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]/[survey_section_id]/[question_id]/product/new": [~27,[2,3,4,5,6]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]/[survey_section_id]/[question_id]/product/[product_id]": [25,[2,3,4,5,6,7]],
		"/(app)/[survey_context=survey_context]/surveys/[survey_response_id]/[survey_section_id]/[question_id]/product/[product_id]/images": [~26,[2,3,4,5,6,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';